<template lang="html">
  <div class="module-root-wrapper">
    <transition name="fade" mode="out-in">
      <div v-if="ready" key="content" class="module-root">
        <slot />
      </div>
      <div v-else key="loading" class="module-root">
        <slot name="loading" :loaded="loaded" :error="error">
          <LoadingSpinner :error="error" />
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingSpinner from '@components/LoadingSpinner'

import ModuleRootMixin from '@mixins/ModuleRootMixin'

export default {
  components: {
    LoadingSpinner
  },
  mixins: [
    ModuleRootMixin
  ]
}
</script>

<style lang="scss" scoped>
.module-root-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.module-root {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s;
}
</style>
