import VueRouter from 'vue-router'

export default new VueRouter({
  mode: 'history',
  base: '/admin',
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('./views/AdminDashboard')
    },
    {
      path: '/roles',
      name: 'legacyRoleManagement',
      component: () => import('./views/LegacyRoleManagement')
    },
    {
      path: '/portal-features',
      name: 'portalFeatures',
      component: () => import('./views/PortalFeatures')
    },
    {
      path: '/power-bi',
      name: 'powerBi',
      component: () => import('./views/PowerBi')
    },
    {
      path: '/users',
      name: 'userManagement',
      component: () => import('./views/UserManagement')
    },
    {
      path: '/role-groups',
      name: 'roleGroups',
      component: () => import('./views/RoleGroups')
    }
  ]
})
