{register} = require 'src/js/infrastructure/setup-registry'
{waitscreen} = require 'src/js/infrastructure'
{postJson} = require 'src/coffee/infrastructure/ajax'
{getModal: modal} = require 'src/coffee/controls/modals'

{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'
{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{notifications} = require 'src/js/infrastructure'

entityAssignmentSetup = ($ctx) ->
    $ctx.find('#saveButton').on 'click', saveUserxEntity

saveUserxEntity = (event) ->
    $saveButton = $ event.currentTarget
    url = $('#saveButton').data 'url'
    userId = $('#UserId').val()
    username = $('#Username').val()
    $grid = $('#userxentitygrid').staticgrid()[0]
    changedStates = $grid.state.changes()
    currentEntities = $grid.state.rows()
    data =
        userId: userId
        username: username
        states: changedStates
        entitiesForUser: currentEntities

    promise = postJson url, data
    .then makeRefreshHandler()
    .then makeSuccessHandler $saveButton
    .fail makeErrorHandler $saveButton

    waitscreen.waitFor promise


userManagementSetup = ($ctx) ->
    $entityMananagmentForUser = $ctx.find '#entityMananagmentForUser'
    $entityMananagmentForAdmin = $ctx.find '#entityMananagmentForAdmin'

    $tableUser = $ctx.find '#gridUser'
    gridUser = $tableUser.staticgrid()[0]

    $tableAdmins = $ctx.find '#gridAdmins'
    gridAdmins = $tableAdmins.staticgrid()[0]

    for button in $ctx.find('#users .button-container a.btn')
        gridUser.bindToSelection $ button

    for button in $ctx.find('#admins .button-container a.btn')
        gridAdmins.bindToSelection $ button

    $tableUser.on 'change.selection', (event) ->
        row = gridUser.table.row('.selected')
        if row.length > 0
            $entityMananagmentForUser.attr 'href', gridUser.completeUrlFromSelection $entityMananagmentForUser.data 'url'

    $tableAdmins.on 'change.selection', (event) ->
        row = gridAdmins.table.row('.selected')
        if row.length > 0
            $entityMananagmentForAdmin.attr 'href', gridAdmins.completeUrlFromSelection $entityMananagmentForAdmin.data 'url'

roleSetup = ($ctx) ->
    assignedSelectedRoles = (event) ->
        event.preventDefault()
        event.stopPropagation()
        $grid = $('#available-roles-grid').staticgrid()[0]

        selectedRoles = $grid.table.rows {selected: true}
        .data()
        .pluck('roleId')
        .toArray()

        $.ajaxSettings.traditional = true
        modal '/Users/AssignRole/', {roleIds: selectedRoles}
        .then ($modal) ->
            $.ajaxSettings.traditional = false
            $select = $('#selected-entity').selectize()
            $modal.find('#assign-role-button').on 'click', ->
                $assignedGrid = $('#assigned-roles-grid').staticgrid()[0]
                $select = $('#selected-entity').selectize()[0].selectize
                roles = JSON.parse(document.getElementById('roles-to-assign').innerText)
                for role in roles
                    role.DT_RowId  = role.assignedRoleId
                    role.entityId  = $select.items[0]
                    role.forEntity = $select.options[$select.items[0]].text.trim()
                $assignedGrid.table.rows.add roles
                $assignedGrid.table.draw()
                $('#assign-roles-modal').modal('hide')

    $ctx.find('#assign-selected-roles-button').on 'click', assignedSelectedRoles

    submitAssignedRoles = (event) ->
        savedMessage = $(event.currentTarget).data 'saved-message'
        $assignedGrid = $('#assigned-roles-grid').staticgrid()[0]
        roles = $assignedGrid.state.rows()
        userId = $('#userId').val()
        postJson '/Users/AssignRoles',
            userId: userId
            roles: roles
        .done (result) -> notifications.success savedMessage
        .fail (result) -> notifications.error result

    $ctx.find('#submitRolesButton').on 'click', submitAssignedRoles

register 'App.UserManagement.Setup', userManagementSetup
register 'App.UserManagement.UserxEntity', entityAssignmentSetup
register 'App.UserManagement.RoleSetup', roleSetup

module.exports = {
    entityAssignmentSetup,
    userManagementSetup,
    roleSetup
}

module.exports.__esModule = true
module.exports.default = userManagementSetup
