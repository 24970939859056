<template lang="html">
  <div :class="{[$style.spinner]: true, [$style.error]: error}">
    <FontAwesomeIcon :icon="icons.loading" size="5x" :pulse="!error" />
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    error: Boolean
  },
  computed: {
    icons () {
      return {
        loading: faSpinner
      }
    }
  }
}
</script>

<style lang="scss" module>
.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(#000000, 0.2);

  transition: color 0.25s;

  &.error {
    color: rgba(#4b0000, 0.4);
  }
}
</style>
