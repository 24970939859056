<template lang="html">
  <div class="administration-nav">
    <NavButton :icon="icons.dashboard" route="dashboard" :permissions="[]" />

    <NavButton
      class="legacy-roles"
      :icon="icons.legacyRoleManagement" route="legacyRoleManagement"
      :permissions="['FullAdmin']"
    />
    <NavButton :icon="icons.portalFeatures" route="portalFeatures" :permissions="['PartnerAdmin']" />
    <NavButton :icon="icons.powerBi" route="powerBi" :permissions="['PowerBiAdmin']" />
    <NavButton :icon="icons.legacyUsers" route="userManagement" :permissions="['PartnerAdmin']" />
    <NavButton :icon="icons.roleGroups" route="roleGroups" :permissions="['PartnerAdmin']" />

    <NavButton
      class="legacy-users" :icon="icons.legacyUsers"
      route="/Users" legacy
      :permissions="['PartnerAdmin']"
    >
      <IxRes>administration.nav.links.users</IxRes>
    </NavButton>
    <NavButton
      :icon="icons.legacyPermissions"
      route="/Administration/Role" legacy
      :permissions="['SecretRoleAdmin']"
    >
      <IxRes>administration.nav.links.permissions</IxRes>
    </NavButton>
  </div>
</template>

<script>
import NavButton from './NavButton'
import {faHome, faUserTag, faFlask, faChartLine, faUsers, faLock, faShieldAlt} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    NavButton
  },
  computed: {
    icons () {
      return {
        dashboard: faHome,
        legacyRoleManagement: faUserTag,
        portalFeatures: faFlask,
        powerBi: faChartLine,
        legacyUsers: faUsers,
        legacyPermissions: faLock,
        roleGroups: faShieldAlt
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.administration-nav {
  width: 250px;

  display: flex;
  flex-direction: column;
  padding-top: 2.5em;

  box-shadow: 3px 3px 4px 1px hsl(0, 0, 80%);

  > :not(:last-child) {
    margin-bottom: 0.5em;
  }

  > .legacy-roles, > .legacy-users {
    margin-top: 2em;
  }
}
</style>
