<template lang="html">
  <IxModuleRoot
    :translation-namespaces="['administration', 'Common.SR_Common']"
    :module="module"
    :module-path="['administration']"
    :setup="loadPrerequisiteData"
    route="/admin"
  >
    <div class="administration-root">
      <AdministrationNav />

      <div class="administration-section">
        <router-view />
      </div>
    </div>

    <TranslationControls />
  </IxModuleRoot>
</template>

<script>
import {mapActions} from 'vuex'
import IxModuleRoot from '@components/IxModuleRoot'
import TranslationControls from '@components/translation/TranslationControls'

import AdministrationNav from './navigation/AdministrationNav'

import SelfDestruct from '@mixins/SelfDestruct'

export default {
  components: {
    IxModuleRoot,
    AdministrationNav,
    TranslationControls
  },
  mixins: [
    SelfDestruct
  ],
  computed: {
    module () {
      return () => import('@store/modules/administration')
    }
  },
  methods: {
    ...mapActions('administration', [
      'loadPrerequisiteData'
    ])
  }
}
</script>

<style lang="scss" scoped>
::v-deep .administration-root {
  height: 100%;
  display: flex;

  --light: #eeeeee;
  --dark: #393e46;
  --darker: #222831;
  --accent: var(--brand-primary, #00adb5);
  --disabled: #959595;
  --error: #99311b;

  color: var(--dark);

  .administration-section {
    flex: 1;
    display: flex;
    flex-direction: column;

    min-height: 0;
    overflow-y: auto;
  }
}
</style>
