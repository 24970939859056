{postJson} = require 'src/coffee/infrastructure/ajax'
{register}﻿ = require 'src/js/infrastructure/setup-registry'
{notifications} = require 'src/js/infrastructure'

setup = ($ctx) ->
    $ctx.find '#submitButton'
    .on 'click', saveRole

saveRole = (event) ->
    savedMessage = $(event.currentTarget).data 'saved-message'
    grid = $('#assigned-permission-grid').staticgrid()[0]
    rows = grid.state.rows()

    data = {}
    data[element.name] = element.value for element in event.currentTarget.form.elements
    data.assignedPermissions = rows

    postJson event.currentTarget.form.action, data
    .done (result) -> notifications.success savedMessage
    .fail (result) -> notifications.error result

# used for legacy role/permission management (RoleNg)
register 'App.Administration.Role.Edit.Setup', setup
