import {mapActions} from 'vuex'

export default {
  props: {
    module: {
      type: Function,
      default: () => () => Promise.resolve({default: undefined})
    },
    modulePath: {
      type: Array
    },
    translationNamespaces: {
      type: Array
    },
    setup: {
      type: Function
    },
    route: String,
    persistent: Boolean
  },
  data () {
    return {
      loaded: false,
      error: false,
      registeredModulePath: null
    }
  },
  computed: {
    ready () {
      return this.loaded && !this.error
    }
  },
  methods: {
    ...mapActions('i18n', [
      'preloadTranslations'
    ]),
    getModule () {
      const follow = (obj, pathSegment) => obj ? obj[pathSegment] : undefined

      return this.modulePath.reduce(follow, this.$store.state)
    }
  },
  async created () {
    try {
      const [{default: module}] = await Promise.all([
        this.module(),
        this.translationNamespaces
          ? this.preloadTranslations(this.translationNamespaces)
          : Promise.resolve()
      ])

      if ((module && !this.modulePath) || (this.modulePath && !module)) {
        throw new Error('If dynamic module loading is enabled, both `module` and `modulePath` props have to be supplied')
      }

      if (module && this.modulePath) {
        const existingModule = this.getModule()
        if (!existingModule) {
          this.$store.registerModule(this.modulePath, module)
        } else if (!this.persistent) {
          throw new Error(`moduleRoot is not persistent but module '${this.modulePath}' is already registered`)
        }

        this.registeredModulePath = this.modulePath
      }

      if (this.setup) {
        await this.setup()
      }

      if (this.route && this.$router) {
        const path = decodeURIComponent(location.pathname)
        const localPath = path.replace(new RegExp(`^${this.route.replace('/', '\\/')}`), '') || '/'
        if ((!path.startsWith(this.route) || this.$route.path !== localPath)) {
          this.$router.push(localPath)
        }
      }
    } catch (error) {
      console.error(error)
      this.error = true
    } finally {
      this.loaded = true
    }
  },
  beforeDestroy () {
    if (this.registeredModulePath && !this.persistent) {
      this.$store.unregisterModule(this.registeredModulePath)
    }
  }
}
