<template lang="html">
  <div
    :class="{[$style.active]: active, [$style.container]: true, [$style.forbidden]: forbidden}"
    @click="onClick"
  >
    <FontAwesomeIcon :icon="icon" fixed-width />

    <div :class="$style.text">
      <slot>
        <IxRes>administration.nav.links.{{ route }}</IxRes>
      </slot>
    </div>

    <FontAwesomeIcon v-if="legacy" :icon="icons.legacy" size="xs" />
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    FontAwesomeIcon
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    icon: Object,
    route: String,
    legacy: Boolean,
    permissions: {
      type: Array,
      required: true
    }
  },
  computed: {
    active () {
      return this.$route.name === this.route
    },
    forbidden () {
      return this.permissions.length && !this.permissions.some(this.$can)
    },
    icons () {
      return {
        legacy: faExternalLinkAlt
      }
    }
  },
  methods: {
    onClick () {
      if (this.active || this.forbidden) return

      if (this.legacy) {
        window.location.href = this.route
      } else {
        this.$router.push({name: this.route})
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;

  padding: 0.5em 1em;
  transition: background-color 0.25s, color 0.25s;
  color: var(--dark);
  font-size: 1.2em;

  cursor: pointer;

  .text {
    margin-left: 0.5em;
  }

  &:hover {
    color: var(--darker);
    background-color: var(--light);
  }

  &.active {
    color: var(--light);
    background-color: var(--accent);
  }

  &.forbidden {
    color: var(--disabled);
    cursor: not-allowed;

    &:hover {
      color: var(--error);
    }
  }
}
</style>
